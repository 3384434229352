@import '../../style/variables.scss';

header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

address {
    font-style: normal;
    font-style: normal;
    font-weight: 500;
    font-size: $product-name-fz;
    line-height: 20px;
}

.menu-button {
    cursor: pointer;
}

.header {
    height: 76px;
    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 25px;
    }
    &_address {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 17px;
    }
    &_mob {
        height: 76px;
        justify-content: space-between;
        align-items: center;
        display: none;
    }
}


@media (max-width: 767.98px) {
    .header {
        display: none;
        &_mob {
            display: flex;
        }
    }
}