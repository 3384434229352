@import "../../style/variables.scss";

.article {
	color: $text-color;
	font-size: $price-fz;
	line-height: 18px;
	max-width: 700px;
	&-text {
		font-weight: 500;
		display: block;
		padding: 10px 0 10px 0;
	}
	&-header {
		font-weight: 800;
		padding: 10px 0 0 0;
	}
	&-subheader {
		font-weight: 800;
		display: block;
		padding: 10px 0 10px 0;
	}
}
