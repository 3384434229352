@import "../../style/variables.scss";

.total {
	color: $text-color;
	font-weight: 800;
	font-size: $product-name-fz;
	line-height: 20px;
	width: 100%;
	padding-top: 16px;

	&-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}
