@import "../../style/variables.scss";

.cart {
	&-items {
		padding-bottom: 28px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-width: 209px;
	}
}

.checkout-pop {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	&__left {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		border-right: 1px solid #f0f0f0;
		padding-right: 41px;
	}
	&__right {
		width: 50%;
		height: 100%;
		padding-left: 23px;
	}
}

.form {
	color: $text-color;

	&__section-header {
		font-weight: 800;
		font-size: $product-name-fz;
		line-height: 20px;
		padding-top: 9px;
		padding-bottom: 16px;
	}

	input {
		width: 100%;
		height: 30px;
		border: 1px solid #fff;
		border-bottom: 1px solid #d9d9d9;
		margin-top: 8px;
		margin-bottom: 8px;
		font-weight: 500;
		font-size: $price-fz;
		line-height: 18px;
		outline: none;
	}

	input#email {
		margin-bottom: 23px;
	}
}

.cart-confirm {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-transform: uppercase;
	font-weight: 800;
	font-size: $price-fz;
	line-height: 18px;
	transition: $transition;
	border: none;
	background: #fff;
	padding-top: 14px;

	&-button {
		display: grid;
		grid-auto-columns: 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas: "button-text button-text button-text button-text rocket-icon .";

		background-color: $main-color;
		width: 338px;
		height: 51px;
		border-radius: 30px;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		cursor: pointer;
		&:active {
			box-shadow: none;
		}
	}
}

.button-text {
	grid-area: button-text;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	--animate-duration: 1s;
}

.rocket-icon {
	grid-area: rocket-icon;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
