@import "../../style/variables.scss";

.navbar-item {
	color: $text-color;
	font-weight: 500;
	font-size: $translate-btn-fz;
	line-height: 13px;
	text-decoration: none;
	cursor: pointer;

	&_active {
		font-weight: 800;
	}
}

.navbar-slide {
	height: 25px;
	width: 105px;
}

@media (min-width: 768px) {
	.navbar-item {
		font-size: $category-name-fz;
	}
}
