@import "../../style/variables.scss";

.nav {
	&-title {
		color: $text-color;
		font-weight: 800;
		font-size: $category-name-fz;
		line-height: 23px;
	}
	&-list {
		padding-top: 29px;
		list-style: none;
	}
	&-link {
		display: block;
		text-decoration: none;
		padding-bottom: 30px;
		font-weight: 500;
		font-size: $product-name-fz;
		line-height: 20px;
		color: $text-color;
		transition: all 0.1s;
		&_active {
			font-weight: 800;
		}
		:hover {
			font-weight: 800;
		}
	}
}
