@import '../../style/variables.scss';

.lang-toggler {
    width: 68px;
    height: 40px;
    transition: $transition;
    height: 100%;
}

.Dropdown-control {
    border-radius: 20px;
    background: $secondary-color;
    border: none;
    font-size: $translate-btn-fz;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: $text-color;
    padding-left: 15px;
}

.Dropdown-placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: $text-color;
    font-size: $translate-btn-fz;
    cursor: pointer;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #000;
}

.Dropdown-arrow {
    border-color: #000 transparent transparent;
}

.Dropdown-option {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: $text-color;
    font-size: $translate-btn-fz;
    background-color: $secondary-color;
    padding-left: 15px;
    height: 32px;
    &:last-child {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    &:first-child {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
}

.Dropdown-option.is-selected {
    background-color: $secondary-color;
}

.Dropdown-menu {
    border: none;
    background:none;
}
