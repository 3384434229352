@import "../../style/variables.scss";

.container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
	width: 100%;
	&::-webkit-scrollbar {
		display: none;
	}
}

.App {
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transition: $transition;
	min-height: 100%;
}

.slided {
	top: 91px;
	left: 262px;
	right: -262px;
	border-radius: 20px;
	box-shadow: $shadow;
}
