@import '../../style/variables.scss';

.swiper {
    width: 100%;
    height: 204px;
    border-radius: 14px;
    // margin-top: 16px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #D9D9D9;
    opacity: 1;

    &-active {
        background: $main-color;
    }
}

.swiper-button {
    &-next::after {
        color: #fff;
    }

    &-prev::after {
        color: #fff;
    }

    &-disabled::after {
        color: $secondary-color;
    }
}

@media (max-width: 767px) {
    .swiper-button {
        &-next::after {
            display: none;
        }

        &-prev::after {
            display: none;
        }
    }
}