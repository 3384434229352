@import "../../style/variables.scss";

.success {
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	margin-top: 20px;
	&-cat {
		width: 27%;
	}
	&-heading {
		font-weight: 800;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
	}
	&-description {
		font-weight: 500;
		font-size: $product-name-fz;
		line-height: 20px;
		text-align: center;
	}
	&-button {
		display: block;
		margin: 0 auto;
		margin-top: 100px;
		background-color: #fdbf26;
		height: 51px;
		border-radius: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 800;
		font-size: $price-fz;
		line-height: 18px;
		color: $text-color;
		text-transform: uppercase;
		cursor: pointer;
		width: 90%;
	}
}

@media (min-width: 767.98px) {
	.success-button {
		margin-top: 40px;
	}
}

@media (max-width: 767.98px) {
	.success-cat {
		width: 55%;
	}
}
