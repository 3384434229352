@import '../../style/variables.scss';

.top {
    height: 88px;
    width: 100%;
    box-shadow: $shadow;
    background: #fff;
    z-index: 10;
    position: absolute;
    top: -200px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition;

    &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
    }

    &-arrow-back {
        height: 40px;
        width: 40px;
        position: absolute;
        top: 25px;
        left: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }


    &_visible {
        position: fixed;
        top: 0;
    }
}

@media (min-width: 767.98px) {
    .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 31px;
        padding-left: 20vw;
        &-arrow-back {
            position: static;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}