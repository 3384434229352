@import '../../style/variables.scss';


.brand-heading {
    color: $text-color;
    padding-left: 18px;

    &__name {
        font-weight: 800;
        font-size: 32px;
        // line-height: 40px;
    }

    &__description {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
    }
}