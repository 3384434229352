@import "../../style/variables.scss";

.cart-item {
	color: $text-color;
	font-weight: 800;
	font-size: $price-fz;
	line-height: 18px;
	margin-top: 1rem;
	max-width: 209px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 0.5fr 1fr;
	grid-auto-columns: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-template-areas:
		"cart-item__counter cart-item__counter cart-item__name cart-item__name cart-item__name cart-item__name cart-item__price cart-item__price"
		"cart-item__empty cart-item__empty cart-item__mods cart-item__mods cart-item__mods cart-item__mods cart-item__empt cart-item__empt"
		"cart-item__minus cart-item__minus cart-item__changer cart-item__changer cart-item__changer cart-item__changer cart-item__plus cart-item__plus";
}

.cart-item__counter {
	grid-area: cart-item__counter;
}

.cart-item__name {
	grid-area: cart-item__name;
}

.cart-item__price {
	grid-area: cart-item__price;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
}

.cart-item__empty {
	grid-area: cart-item__empty;
}

.cart-item__empt {
	grid-area: cart-item__empt;
}

.cart-item__mods {
	grid-area: cart-item__mods;
	font-weight: 500;
	font-size: $rest-descr-fz;
	line-height: 15px;
	color: #8a8a8a;
	display: flex;
	justify-content: flex-start;
	gap: 5px;
}

.cart-item__minus {
	grid-area: cart-item__minus;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.cart-item__plus {
	grid-area: cart-item__plus;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
}

.cart-item__changer {
	grid-area: cart-item__changer;
	color: $main-color;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

@media (max-width: 767.98px) {
	.cart-item {
		max-width: none;
	}
}
