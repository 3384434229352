@import '../../style/variables.scss';

.screen {
    width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 20;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_left {
        &_open {
            left: 0;
        }
        &_closed {
            left: 100%;
        }
    }
    &_right {
        &_open {
            right: 0;
        }
        &_closed {
            right: 100%;
        }
    }
}

