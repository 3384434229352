@import '../../style/variables.scss';


.topbar {
    height: 108px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: -300px;
    left: 0;
    z-index: 10;
    transition: all .2s;
    color: $text-color;

    &_arrow-back {
        height: 40px;
        width: 40px;
        position: absolute;
        top: 23px;
        left: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &_visible {
        position: fixed;
        top: 0;
        box-shadow: $shadow;
    }

    &__restaurant-name {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        padding-top: 30px;
    }
}

.topbar__navigation-slider .swiper {
    height: 36px;
    margin-top: 13px;
}

@media (min-width: 767.98px) {
    .topbar {
        display: none;
    }
}