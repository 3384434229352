@import "../../style/variables.scss";

.product-pop {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	&__img {
		display: block;
		height: 221px;
	}

	&__price {
		min-width: 3em;
		text-align: end;
	}

	&__info {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		border-right: 1px solid #f0f0f0;
		padding-right: 23px;
	}

	&__heading {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		font-weight: 800;
		font-size: 20px;
		line-height: 25px;
		margin-top: 15px;
	}

	&__description {
		width: 100%;
		height: 86px;
		font-weight: 500;
		font-size: $product-name-fz;
		line-height: 20px;
		margin-top: 2px;
	}

	&__counter {
		width: 100%;
		margin-top: 9px;
	}

	&__button {
		width: 100%;
		margin-top: 21px;
	}

	&__mods {
		width: 50%;
		padding-left: 23px;
		align-self: flex-start;
		@include y-overflower;
	}
}

.product-mob {
	display: none;
	margin-top: 23px;
	height: 100%;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	overflow-y: overlay;
	&::-webkit-scrollbar {
		display: none;
	}

	&__img {
		display: block;
		height: 251px;
	}

	&__heading {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		font-weight: 800;
		font-size: 18px;
		line-height: 23px;
		margin-top: 16px;
	}

	&__price {
		min-width: 3em;
		text-align: end;
	}

	&__description {
		width: 100%;
		font-weight: 500;
		font-size: $price-fz;
		line-height: 18px;
		margin-top: 9px;
	}

	&__counter {
		width: 100%;
		margin-top: 9px;
	}

	&__button {
		position: fixed;
		bottom: 30px;
		width: 90%;
		margin-top: 21px;
		margin-bottom: 35px;
		min-height: 50px;
	}
	&__whitespace {
		min-height: 140px;
	}
}

@media (max-width: 767.98px) {
	.product-pop {
		display: none;
	}

	.product-mob {
		display: flex;
	}
}
