@import '../../style/variables.scss';

.category__name {
    font-weight: 800;
    font-size: $category-name-fz;
    line-height: 23px;
    color: $text-color;
    padding: 29px 19px 0 14px;
    height: 100%;
    max-width: 1024px;
}

.category__products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #F0F0F0;
    border-radius: 20px;
    margin-top: 18px;
}

@media (max-width: 991.98px) {
    .category__products {
        border: 1px solid #fff;
    }
}