@import '../../style/variables.scss';

.main {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &-content {
        width: 52%;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-sidebar {
        width: 24%;
        padding: 0 10px 10px 10px;
        position: relative;
    }
}

.sidebar-right {
    padding-left: 36px;
}

@media (max-width: 767.98px) {
    .main {
        padding-top: 15px;
        flex-direction: column;
        align-items: center;
    }

    .main-sidebar {
        display: none;
    }
    
    .main-content {
        width: 100%;
    }
}