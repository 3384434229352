.button-spinner {
    // margin: 100px auto 0;
    width: 100%;
    min-height: 58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
}

.button-spinner>div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.button-spinner .button-spinner1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.button-spinner .button-spinner2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}