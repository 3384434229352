@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import-normalize;
@import './variables.scss';



html, * {
    scroll-behavior: smooth;
    font-family: 'Mulish';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

.hidden {
    visibility: hidden;
}


section,
header,
footer {
    padding: 0 15px;
}

@media (min-width: 575.98px) {

    section,
    header,
    footer {
        padding: 0 calc(50vw - 270px);
    }
}

@media (min-width: 767.98px) {

    section,
    header,
    footer {
        padding: 0 calc(50vw - 360px);
    }
}

@media (min-width: 991.98px) {

    section,
    header,
    footer {
        padding: 0 calc(50vw - 540px);
    }
}

