@import "../../style/variables.scss";

.modal {
    padding: 18px 41px;
    background-color: #fff;
    position: fixed;
    top: 200%;
    left: 50%;
    height: 55%;
    width: 56%;
    min-width: 767.98px;
    min-height: 498px;
    transform: translate(-50%, -50%);
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: $transition;
    border-radius: 20px;
    &_active {
        top: 50%;
        //animation
        animation: slideUp .3s;
        animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    &__close {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 13px;
    }
    &__overlay {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgba(17, 17, 26, 0.7);
        display: none;
        z-index: 11;
        opacity: 0;
        animation: casper .4s both;
        &_active {
            display: block;
        }
    }
}

@media (max-width: 767.98px) {
    //styles of smaller modal here
    .smaller {
        top: 100% !important;
        max-height: 250px !important;
        animation: slideUpSmaller .3s !important;
    }

    .modal {
        min-width: 0;
        width: 100%;
        height: 92vh;
        padding: 18px 29px;
        //animation
        animation: slideUpMob .3s;
        animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

        // &__overlay {
        //     height: 100%;
        //     width: 100%;
        //     &_active {
        //         display: flex;
        //         justify-content: center;
        //         align-items: flex-end;
        //         height: 100%;
        //         width: 100%;
        //     }
        // }

        &_active {
            top:55%;
            border-radius: 20px 20px 0 0;
        }
    }
}

@keyframes casper {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        top: 200%;
    }

    to {
        top: 50%;
    }
}

@keyframes slideUpMob {
    from {
        top: 200%;
    }

    to {
        top: 55%;
    }
}

@keyframes slideUpSmaller {
    from {
        top: 200%;
    }

    to {
        top: 100%;
    }
}
