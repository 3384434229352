@import "../../style/variables.scss";

.site-nav {
	background: $secondary-color;
	height: 100vh;
	width: 100%;
	&__content {
		height: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__header {
		width: 390px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 28px 33px;
	}
	&__title {
		font-weight: 800;
		font-size: 32px;
		line-height: 40px;
	}
	&__close {
		cursor: pointer;
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__item {
		width: 243px;
		height: 58px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 21px;
		padding-left: 33px;
		font-weight: 500;
		font-size: $product-name-fz;
		line-height: 20px;
		cursor: pointer;
		&:hover {
			background: #ededed;
		}
	}
}
