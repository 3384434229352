@import "../../style/variables.scss";

.button {
	display: block;
	margin: 0 auto;
	background-color: $main-color;
	height: 51px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 800;
	font-size: $price-fz;
	line-height: 18px;
	color: $text-color;
	text-transform: uppercase;
	cursor: pointer;
	&_wide {
		width: 96%;
	}
}
