@import "../../style/variables.scss";

.checkout {
	&__section {
		width: 90%;
		padding-top: 31px;
		&-title {
			font-weight: 800;
			font-size: $product-name-fz;
			line-height: 25px;
			width: 100%;
		}
	}
}
